import React from "react"
import { values } from 'lodash'

const PROJECT =  "Marketing Site"

const standardEvents = {
  click: "Click",
  entry: "Entry",
  impression: "Impression",
  error: "Error",
}

const events = {
  ...standardEvents,
}

function getInstance() {
  if (typeof window != 'undefined') {
    return window.analytics
  }
}

const identify = (id, properties) => {
  console.log("Segment Event:", "Identify", id, properties)
  const analytics = getInstance()
  if (analytics) {
    analytics.identify(id, properties)
  }
}

// For many events, we only need to record the target
// To make this simpler, we also accept a simple string instead of a { Target: "Value" } object
const convertStringToTarget = (eventName, properties) => {
  if (typeof properties === 'string' && values(standardEvents).includes(eventName)) {
    return { target: properties }
  }
  return properties
}

const track = (eventName, properties={}) => {
  console.log("Segment Event:", eventName, properties)
  let props = properties
  // For standard events, we can also accept just a string in place of properties
  // if a string is given, it is for the "Target" property
  if (typeof props === 'string' && Object.values(standardEvents).includes(eventName)) {
    props = { target: properties }
  }
  const analytics = getInstance()
  if (analytics) {
    analytics.track(eventName, props)
  }
}

const createAnalytics = (injectProperties={}) => {
  const analyticsFunctions = {
    identify: (id, properties) => identify(id, properties),
  }

  // Add custom functions that align with our event tracking
  Object.keys(events).map((key, index) =>
    analyticsFunctions[key] = properties => {
      const cleanedProperties = convertStringToTarget(events[key], properties)
      track(events[key], { ...injectProperties, ...cleanedProperties})
    })

  return analyticsFunctions
}

const defaultState = {analytics: createAnalytics()}

// defaulting to no injected properties, should be overwritten in provider
const AnalyticsContext = React.createContext(defaultState)

class AnalyticsProvider extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      location: null
    }
    this.analytics = createAnalytics({
      project: PROJECT,
      page: props.uri,
    })
  }

  componentDidMount() {
    // caching this in state b/c otherwise it doesn't get set correctly on initial page hit
    this.setState({ location: this.props.location })
    this.analytics.impression()
  }

  render() {
    const { children } = this.props
    return (
      <AnalyticsContext.Provider
        value={{
          analytics: this.analytics,
          location: this.state.location
        }}
      >
        {children}
      </AnalyticsContext.Provider>
    )
  }
}
export default AnalyticsContext

export { AnalyticsProvider }

