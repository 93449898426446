import "./src/css/main.css"

import React from "react"

import { AnalyticsProvider } from "./src/providers/analytics-provider"

export const wrapPageElement = ({ element, props }) => {
  return (
    <AnalyticsProvider {...props}>
      {element}
    </AnalyticsProvider>
  )
}